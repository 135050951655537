<template>
  <div id="settings-wrapper">
    <v-card id="settings" class="py-2 px-4" color="rgba(0, 0, 0, .1)" dark flat link min-width="100"
            style="position: fixed; top: 115px; right: -35px; border-radius: 8px;">
      <v-icon large>
        mdi-cog-outline
      </v-icon>
    </v-card>

    <v-menu v-model="menu"
            :close-on-content-click="false"
            activator="#settings"
            bottom
            content-class="v-settings"
            left
            nudge-left="8"
            offset-x
            origin="top right"
            transition="scale-transition">
      <v-card class="text-center mb-0" width="400">
        <v-card-text>
          <strong class="mb-3 d-inline-block">{{$t('settings-side.interface-colors')}}</strong>
          <v-item-group v-model="color">
            <v-item v-for="color in colors" :key="color" :value="color">
              <template v-slot="{ active, toggle }">
                <v-avatar :class="active && 'v-settings__item--active'"
                          :color="color" class="v-settings__item" size="25" @click="toggle"/>
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary"/>

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              {{$t('settings-side.dark-mode')}}
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
              <v-switch v-model="$vuetify.theme.dark" class="ma-0 pa-0" color="secondary" hide-details/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import {mapMutations} from 'vuex'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      color: '#4CAF50',
      colors: [
        '#4CAF50',
        '#2196F3',
        '#00CAE3',
        '#ff9800',
        '#E91E63',
        '#FF5252',
        '#172b4d',
        '#190b4d',
        '#FFC107',
        '#3F51B5',
        '#607D8B',
        '#03A9F4',
        '#795548',
        '#757575',
        '#000000',
        '#80CBC4',
        '#7986CB',
      ],
      menu: false,
    }),
    watch: {
      color(val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
      },
    },
    methods: {
      ...mapMutations({
        setBarImage: 'SET_BAR_IMAGE',
      }),
    },
  }
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
